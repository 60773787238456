// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-posts-first-post-mdx": () => import("./../../../src/posts/first-post.mdx" /* webpackChunkName: "component---src-posts-first-post-mdx" */),
  "component---src-posts-understanding-ts-mdx": () => import("./../../../src/posts/understanding-ts.mdx" /* webpackChunkName: "component---src-posts-understanding-ts-mdx" */)
}

